import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.TARIFF_LIST,
    loadChildren: () => import('./tariff-list/tariff-list.module').then((m) => m.TariffListModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.TARIFF_DETAIL,
    loadChildren: () => import('./tariff-detail/tariff-detail.module').then((m) => m.TariffDetailModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.TRANSIT_TIME_FILE_REQUEST,
    loadChildren: () => import('./transit-time-file/transit-time-file.module').then((m) => m.TransitTimeFileModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.RATE_FILE_REQUEST,
    loadChildren: () => import('./rate-file/rate-file.module').then((m) => m.RateFileModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.BID_FILE_REQUEST,
    loadChildren: () => import('./bid-file-request/bid-file-request.module').then((m) => m.BidFileRequestModule),
    canLoad: [XpoAuthenticationGuard],
  },

  // ADD ANY NEW ROUTE / PAGE HERE
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
